
import { defineComponent } from 'vue';
import { reportProps } from '@/helpers/report-comp-props';

export default defineComponent({
  props: {
    ...reportProps,
  },
  setup(props) {
    const userReport = props.records ? props.records[0].data : {};

    return { userReport };
  },
});
